import '../App.css';
import { StaticHeader ,Footer} from '../ui-components';

import React, { useState, useEffect } from "react";
import {Amplify,  API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { Flex} from "@aws-amplify/ui-react";


import awsconfig from "../aws-exports";

import { useSearchParams } from "react-router-dom";

import moment from 'moment-timezone';


Amplify.configure(awsconfig);



 //Stateを使ってコメント投稿を管理
function Comments(props) {

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  

// SerchParamsでURLにくっつけている変数の取得
  const [searchParams] = useSearchParams();
  console.log("searchParams", searchParams)
  const articleId = searchParams.get('article_id');
  console.log("articleId", articleId)


// コメントが紐づく記事ID、一旦ハードコーディング。今後呼び出した元ページに紐づくよう変更予定
//  const articleId = "1de59468-9d8b-4f67-9fde-9b0cda61a1db";

  useEffect(() => {
    fetchComments();
  }, []);


// コメントリストの一覧を表示
  const fetchComments = async () => {
    const commentsData = await API.graphql(
      graphqlOperation(queries.listComments, {
        filter: { ContentsComment: { eq: articleId } },
//        limit: 30, // 1ページあたりのアイテム数
//      　nextToken: lastItem // 次のページの最初のアイテム
      })
    );
//    setComments(commentsData.data.listComments.items);

// コメントを降順で表示
  const sortedComments = commentsData.data.listComments.items.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
    setComments(sortedComments);
//　  setLastItem(commentsData.data.listComments.nextToken);
  };


// コメントのページ分割
//const [currentPage, setCurrentPage] = useState(1);
//const [lastItem, setLastItem] = useState(null);


// コメントの追加
  const addComment = async () => {
    if (newComment === "") return;
    await API.graphql(
      graphqlOperation(mutations.createComment, {
        input: { Comment: newComment, ContentsComment: articleId },
      })
    );
    setNewComment("");
    fetchComments();
  };



 
  const styleCommentTime ={
    fontSize:"0.5rem",
    marginLeft:"auto",
    marginRight:"auto",
    textAlign:"right",
   
 };
 
  const styleCommentForm ={
    backgroundColor:"white",
    fontSize:"0.8rem",
    flex:"0.3",
    marginLeft:"auto",
    marginRight:"auto",
    padding:"0.5rem",
    width: "95%"
 };
 
 

  return (
    <div>
    <Flex
     direction="column"
     alignItems="center"
    >
        <textarea style={styleCommentForm}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <button onClick={addComment} className="btnCafote">コメント投稿</button>
    </Flex> 
        {comments.map((comment) => (
        <Flex
          textAlign="left"
          className="textCard"
          gap="0rem"
          margin="0rem"
          >
        <div key={comment.id}  className="textComment">
          <div style={styleCommentTime}>
            {moment.tz(comment.createdAt, 'Asia/Tokyo').fromNow()}
          </div>
          <div>{comment.Comment}</div>
        </div>
        </Flex>
      ))}

    </div>
  );
}

export default Comments;