import { Input, Button, TextField, SelectField } from '@aws-amplify/ui-react';
import { API, Auth, Storage, graphqlOperation} from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import './CSS/Cafote.css';

import Header from '../ui-components/StaticHeader_fix'
import Footer from '../ui-components/Footer_fix'


import { Flex } from "@aws-amplify/ui-react";



function FileUploadComponent() {
  const [uploadedFilePath, setUploadedFilePath] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [uploading, setUploading] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('アップロードする');
  const [title, setTitle] = useState('');
  const [abstract, setAbstract] = useState('');
  const [detail, setDetail] = useState('');
  const [categories, setCategories] = useState(0);
  const [subject, setSubject] = useState('');
  const [isSubjectEnabled, setIsSubjectEnabled] = useState(true);
  const [classLevel, setClassLevel] = useState(0);
  const [classPeople, setClassPeople] = useState(0);
  const [classStyle, setClassStyle] = useState(0);
  const [categoryname, setCategoryName] = useState('');
  const [subjectname, setSubjectName] = useState('');
  const [contentownername, setContentOwnerName] = useState()
  const [classlevelOptions, setClassLevelOptions] = useState([]);
  const [classpeopleOptions, setClassPeopleOptions] = useState([]);
  const [classstyleOptions, setClassStyleOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [meate, setMeate] = useState('');
  const [nerai, setNerai] = useState('');
  const [matome, setMatome] = useState('');

  useEffect(() => {
    const fetchClassLevelOptions = async () => {
      try {
        const response = await API.graphql({
          query:`
            query listQuetions {
              listQuetions(filter: {PageNo: {eq: 1}}) {
                items {
                  PageNo
                  QuetionItem
                  SortNumber
                  id
                }
              }
            }
          `
        })
        //console.log('responseの内容:',response);
        const classlevels = response.data.listQuetions.items;
        classlevels.sort((a, b) => a.SortNumber - b.SortNumber);
        console.log(classlevels);
        const updatedOptions = classlevels.map((classlevel) => ({
          value: classlevel.SortNumber,
          label: classlevel.QuetionItem
        }));
        setClassLevelOptions(updatedOptions);
        console.log('クラスレベルオプションの内容:', updatedOptions);
      } catch (error) {
      console.error('クラスレベルの取得エラー:',error);
      }
    };
    const fetchClassPeopleOptions = async () => {
      try {
        const response = await API.graphql({
          query:`
            query listQuetions {
              listQuetions(filter: {PageNo: {eq: 3}}) {
                items {
                  PageNo
                  QuetionItem
                  SortNumber
                  id
                }
              }
            }
          `
        })
        //console.log('responseの内容:',response);
        const classpeople = response.data.listQuetions.items;
        classpeople.sort((a, b) => a.SortNumber - b.SortNumber);
        console.log(classpeople);
        const updatedOptions = classpeople.map((classpeople) => ({
          value: classpeople.SortNumber,
          label: classpeople.QuetionItem
        }));
        setClassPeopleOptions(updatedOptions);
        console.log('クラス人数オプションの内容:', updatedOptions);
      } catch (error) {
      console.error('クラス人数の取得エラー:',error);
      }
    };
    const fetchClassStyleOptions = async () => {
      try {
        const response = await API.graphql({
          query:`
            query listQuetions {
              listQuetions(filter: {PageNo: {eq: 5}}) {
                items {
                  PageNo
                  QuetionItem
                  SortNumber
                  id
                }
              }
            }
          `
        })
        //console.log('responseの内容:',response);
        const classstyle = response.data.listQuetions.items;
        classstyle.sort((a, b) => a.SortNumber - b.SortNumber);
        //console.log(classstyle);
        const updatedOptions = classstyle.map((classstyle) => ({
          value: classstyle.SortNumber,
          label: classstyle.QuetionItem
        }));
        setClassStyleOptions(updatedOptions);
        console.log('クラス人数オプションの内容:', updatedOptions);
      } catch (error) {
      console.error('クラス人数の取得エラー:',error);
      }
    };
    const fetchCategoryOptions = async () => {
      try {
        const response = await API.graphql({
          query:`
            query listQuetions {
              listQuetions(filter: {PageNo: {eq: 7}}) {
                items {
                  PageNo
                  QuetionItem
                  SortNumber
                  id
                }
              }
            }
          `
        })
        //console.log('responseの内容:',response);
        const category = response.data.listQuetions.items;
        category.sort((a, b) => a.SortNumber - b.SortNumber);
        console.log(category);
        const updatedOptions = category.map((category) => ({
          value: category.SortNumber,
          label: category.QuetionItem
        }));
        setCategoryOptions(updatedOptions);
        console.log('カテゴリーの内容:', updatedOptions);
      } catch (error) {
      console.error('カテゴリーの取得エラー:',error);
      }
    };
    fetchClassLevelOptions();
    fetchClassPeopleOptions();
    fetchClassStyleOptions();
    fetchCategoryOptions();
  },[]);

  const handleCategoryCahnge = async(e) => {
    const selectedCategory = parseInt(e.target.value);
    console.log('選択したカテゴリー:', selectedCategory);
    setCategories(selectedCategory);
    const selectedLabel = e.target.options[e.target.selectedIndex].label 
    setCategoryName(selectedLabel);
    console.log('EEEE:',selectedLabel)
    

  
      setIsSubjectEnabled(true);
      await fetchSubjectOptions(selectedCategory);
    };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    console.log('FFFFFFF:',e.target.options);
    const selectedsubjectlabel = e.target.options[e.target.selectedIndex].label
    console.log('selectedsubject:',selectedsubjectlabel);
    setSubjectName(selectedsubjectlabel);
  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setSelectedFileName(e.target.files[0].name); // ファイル名を設定
    setButtonLabel(e.target.files[0].name);
  };

  const fetchSubjectOptions = async (selectedCategory) => {
    const ListSubjectsByCategory = `
    query listQuetions ($minnum: Int!, $maxnum: Int!) {
      listQuetions(filter: {PageNo: {eq: 8},SortNumber:{between:[$minnum,$maxnum]}}) {
        items {
          PageNo
          QuetionItem
          SortNumber
          id
        }
      }
    }
    `
    let minnum, maxnum;
    if (selectedCategory === 1){
      minnum = 1;
      maxnum = 6;
    } else if (selectedCategory === 2){
      minnum = 7;
      maxnum = 11;
    } else if (selectedCategory === 3){
      minnum = 12;
      maxnum = 16;
    } else {
      minnum = 1;
      maxnum = 16;
    }




    try {
      const response = await API.graphql({
        query:ListSubjectsByCategory,
        variables:{minnum,maxnum}
      
      })
      //console.log('responseの内容:',response);

      const subjects = response.data.listQuetions.items;
      console.log('minnum:',minnum);
      console.log('category:',selectedCategory);
      subjects.sort((a, b) => a.SortNumber - b.SortNumber);
      console.log('subject',subjects);
      const updatedOptions = subjects.map((subject) => ({
        value: subject.SortNumber,
        label: subject.QuetionItem
      }));
      setSubjectOptions(updatedOptions);
      console.log('科目の内容:', updatedOptions);
    } catch (error) {
    console.error('科目の取得エラー:',error);
    }
  }

  const handleFileUpload = async () => {
    try {
      setUploading(true);

      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.sub;
      const ownername = user.attributes.name;

      const path = `uploads/${userId}/${selectedFile.name}`;
      await Storage.put(path, selectedFile);

      console.log('ファイルがS3にアップロードされました。', path);
      setUploadedFilePath(path);
      setContentOwnerName(ownername);
    } catch (error) {
      console.error('ファイルアップロードエラー:', error);
    } finally {
      setUploading(false);
    }
  };

  const writeToDynamoDB = async () => {
    try {
      const newContent = {
        Title: title,
        Abstract: abstract,
        Detail: detail,
        Category: categories,
        Subject: subject,
        ClassLevel: classLevel,
        ClassPeople: classPeople,
        ClassStyle: classStyle,
        FileSrc: uploadedFilePath,
        CategoryName: categoryname,
        SubjectName: subjectname,
        UserID: contentownername,
        Meate: meate,
   		Nerai:nerai,
  	    Matome:matome
      }

      // GraphQL APIを使用してDynamoDBにデータを書き込む
      const apiResponse = await API.graphql({
        query: `
          mutation createContents($input: CreateContentsInput!) {
            createContents(input: $input) {
              id
              Title
              Abstract
              Detail
              Category
              Subject
              ClassLevel
              ClassPeople
              ClassStyle
              FileSrc
              CategoryName
              SubjectName
              UserID
              Meate
   			  Nerai
  		   	  Matome
            }
          }
        `,
        variables: { input: newContent }, 
      });

      console.log('DynamoDBにデータが書き込まれました。', apiResponse);
    } catch (error) {
      console.error('データ書き込みエラー:', error);
    }
  };




const handleSubmit = async (e) => {
  e.preventDefault();

  // ファイルが選択されているか確認する
  const fileInput = document.getElementById('fileInput');
  if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
    console.error('ファイルが選択されていません。');
    return;
  }

  try {
    await handleFileUpload(); // ファイルのアップロードを実行し待機
    console.info('ファイルのアップロードを実行し待機');
    await writeToDynamoDB(); // DynamoDBへの書き込みを実行し待機
    console.info('DynamoDBへの書き込み');
    // ファイルのアップロードとDynamoDBへの書き込みが完了した後に実行したいコードをここに追加できます
  } catch (error) {
    console.error('処理中にエラーが発生しました:', error);
  }
};
const handleSelectFileClick = () => {
  // ファイル選択用のinput要素をクリックする
  const fileInput = document.getElementById('fileInput');
  if (fileInput) {
    fileInput.value = null; // クリアするためにファイル選択の値をリセットする
    fileInput.click();
  }
};

  return (
    <div>
    
    <Header />
    
     <div className="contentmiddle">

      <form action="/Home"onSubmit={handleSubmit}>
      <Flex
      margin="0px 0px 10px 0px"
      >
       <div class="label">
        <label>
          ファイル
        </label>
       </div>
        
       <div class="inputs">
          <button className="btnCafote" onClick={handleSelectFileClick}>
            選択
          </button>
    　　　<br/>
          {selectedFileName || 'ファイルを選択してください。'}
          <input
            type="file"
            style={{ display: 'none' }}
            id="fileInput"
            onChange={handleFileChange}
          />
        </div>
      </Flex>
    <div class="item">
      <label
      class="label"
      for="title"
      > タイトル</label>
        <TextField
          id="title"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="inputs"
        />
      </div>
     <div class="item">
       <label
        class="label"
        for="abstract"
       > 説明</label>
        <TextField
          id="abstract"
          name="abstract"
          value={abstract}
          onChange={(e) => setAbstract(e.target.value)}
          className="inputs"
        />
      </div>
     <div class="item">
      <label
      class="label"
      for="nerai"
      > ねらい</label>    
        <TextField
          id="nerai"
          name="nerai"
          value={nerai}
          onChange={(e) => setNerai(e.target.value)}
          className="inputs"
         />
     </div>
     <div class="item">
      <label
      class="label"
      for="meate"
      > めあて</label>
          <TextField
          id="meate"
          name="meate"
          value={meate}
          onChange={(e) => setMeate(e.target.value)}
          className="inputs"
        />
      </div>
     <div class="item">  
      <label
      class="label"
      for="matome"
      > まとめ</label>
      <TextField
          id="matome"
          name="matome"
          value={matome}
          onChange={(e) => setMatome(e.target.value)}
          className="inputs"
        />
     </div>
    <div className="item">
      <label className="label" htmlFor="category">学校種別</label>
      <select
        className="selects"
        id="category"
        name="category"
        type="number"
        value={categories}
        onChange={handleCategoryCahnge}
      >
        {categoryOptions.map((option) => (
          <option key={option.value} value={option.value} label={option.label} />
        ))}
      </select>
    </div>
     <div class="item">  
        
      <label
      class="label"
      for="subject"
      > 教科</label>
        <select
        className="selects"
          id="subject"
          name="subject"
          type='number'
          value={subject}
          onChange={handleSubjectChange}
          >
          {subjectOptions.map((option) => (<option key={option.value} value={option.value} label={option.label}>
            </option>
          )
          )}
          </select>
          
      </div>
     <div class="item">
      <label
      class="label"
      for="classLevel"
      > クラスレベル</label>
        <select
        className="selects"
          id="classLevel"
          name="classLevel"
          type="number"
          value={classLevel}
          onChange={(e) => setClassLevel(e.target.value)}>
          {classlevelOptions.map((option) => (<option key={option.value} value={option.value} label={`${option.value}. ${option.label}`}>
            </option>
            )
          )}
        </select>
     </div>
     <div class="item">
      <label
      class="label"
      for="classPeople"
      > クラス人数</label>
        <select
        className="selects"
          id="classPeople"
          name="classPeople"
          type="number"
          value={classPeople}
          onChange={(e) => setClassPeople(e.target.value)}>
          {classpeopleOptions.map((option) => (<option key={option.value} value={option.value} label={`${option.value}. ${option.label}`}>
            </option>
            )
          )}
          </select>
    </div>
     <div class="item">
          <label
          class="label"
          for="classStyle"
          > 授業スタイル</label>
        <select
        className="selects"
          id="classStyle"
          name="classStyle"
          type="number"
          value={classStyle}
          onChange={(e) => setClassStyle(e.target.value)}>
          {classstyleOptions.map((option) => (<option key={option.value} value={option.value} label={`${option.value}. ${option.label}`}>
            </option>
            )
          )}
          </select>
      </div>
      <Button type="submit" className="btnCafote">投稿</Button>
    </form>

      {uploading && <p>ファイルをアップロード中...</p>}
      {uploadedFilePath && (
        <div>
          <p>アップロードしたファイルのパス: {uploadedFilePath}</p>
        </div>
      )}

      </div>

      <Footer />
    
    </div>
  );
}

export default FileUploadComponent;
