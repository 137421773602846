
import  { useEffect, useState } from 'react';
import { Qanswer } from "../models";
import {
  getOverrideProps,
  useDataStoreCreateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { schema } from "../models/schema";
import { Flex, Text, Button } from "@aws-amplify/ui-react";
export default function QuestionItem2(props) {
  const { quetions, overrides, ...rest } = props;
  const [isJoined, setIsJoined] = useState({ button1: false, button2: false, button3: false });
  const [buttonBackgroundColor, setButtonBackgroundColor] =
    useStateMutationAction("rgba(220,222,224,1)");
  const questionItemTwoOnClick = useDataStoreCreateAction({
    fields: {
      UserID: "10",
      AnsID: "10",
      A01: "10",
      A02: "10",
      A03: "10",
      A04: "10",
      A05: "10",
      A06: "10",
    },
    model: Qanswer,
    schema: schema,
  });
  const buttonOnClick = () => {
    setButtonBackgroundColor("#D17091");
  };
  const handleJoinClick = (buttonId) => {
  if (buttonId === 'ansButton') {
    setIsJoined({ ...isJoined, button1: !isJoined.button1 });
  }
};
  return (
    <Flex
      gap="10px"
      direction="column"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      backgroundColor="rgba(255,255,255,1)"
      
      onClick={() => {
        questionItemTwoOnClick();
      }}
      {...getOverrideProps(overrides, "QuestionItem2")}
      {...rest}
    >
    <Button
    className={`btnCafote ${isJoined.button1 ? 'joined' : 'unJoined'}`}
    maxWidth="500px"
    width="70%"
    fontSize="1.5em"
    padding="0.5em"
    children={quetions?.QuetionItem}
    onClick={() => handleJoinClick('ansButton')}
    key={quetions?.SortNumber}
    {...getOverrideProps(overrides, "ansButton")}
    >
    </Button>
      
    </Flex>
  );
}
