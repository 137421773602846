import '../App.css';
// === Add the following ===
import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import Header from '../ui-components/StaticHeader_fix'
import Footer from '../ui-components/Footer_fix'

import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';

import { useSearchParams } from "react-router-dom";
import Comments from './Comments';

import '@aws-amplify/ui-react/styles.css'; // default theme
import './CSS/Cafote.css';

import {
  Card,
  Image,
  View,
  Heading,
  Flex,
  Badge,
  Text,
  Button,
  useTheme,
} from '@aws-amplify/ui-react';


Amplify.configure(awsExports);
// ======

function CommunityList() {
const { tokens } = useTheme();
const [isJoined, setIsJoined] = useState({ button1: false, button2: false, button3: false });

const handleJoinClick = (buttonId) => {
  if (buttonId === 'button1') {
    setIsJoined({ ...isJoined, button1: !isJoined.button1 });
  } else if (buttonId === 'button2') {
    setIsJoined({ ...isJoined, button2: !isJoined.button2 });
  } else if (buttonId === 'button3') {
    setIsJoined({ ...isJoined, button3: !isJoined.button3 });
  }
};

return (

    <>
    <div className="Headerfixed">
      <Header/>
    </div>

    <div class="contentmiddle">
     <Flex
       direction="row"
       wrap="wrap"
       gap="2rem"
      >
      <Card className="CommunityCard">
        <Flex direction="column" alignItems="flex-start">
          <Image
            alt="Road to milford sound"
            src="https://public-cafo.s3.ap-northeast-1.amazonaws.com/wether.jfif"
            width="100%"
          />
          <Flex
            direction="column"
            alignItems="flex-start"
            textAlign="left"
            gap={tokens.space.xs}
          >
            <Flex>
              <Badge size="small" variation="info">
                授業
              </Badge>
              <Badge size="small" variation="success">
                数学
              </Badge>
              <Badge size="small" variation="success">
                地学
              </Badge>
            </Flex>

            <Heading level={5}>
           数学を実世界の問題解決にどのように適用するかを考えよう   
            </Heading>

            <Text as="span">
              2年生の数学の授業で、統計学を用いて地域の気候変動を分析するプロジェクト
            </Text>
            <button
              id="button1"
              className={`btnCafote ${isJoined.button1 ? 'joined' : 'unJoined'}`}
              onClick={() => handleJoinClick('button1')}>
              {isJoined.button1 ? '参加済' : '参加する'}
            </button>
        </Flex>
        </Flex>
      </Card>
      
      <Card className="CommunityCard">
        <Flex direction="column" alignItems="flex-start">
          <Image
            alt="Road to milford sound"
            src="https://public-cafo.s3.ap-northeast-1.amazonaws.com/Trump.jfif"
            width="100%"
          />
          <Flex
            direction="column"
            alignItems="flex-start"
            textAlign="left"
            gap={tokens.space.xs}
          >
            <Flex>
              <Badge size="small" variation="info">
                授業
              </Badge>
              <Badge size="small" variation="success">
                数学
              </Badge>
            </Flex>

            <Heading level={5}>
           確率をゲームを学んでみよう
            </Heading>
            <Text as="span">
            2年生の数学の授業で、確率を楽しく、実際に体感しながら学ぶためのカードゲームを導入しませんか
            </Text>
            <button
              id="button2"
              className={`btnCafote ${isJoined.button2 ? 'joined' : 'unJoined'}`}
              onClick={() => handleJoinClick('button2')}>
              {isJoined.button2 ? '参加済' : '参加する'}
            </button>
        </Flex>
        </Flex>
      </Card>
      
  
      <Card className="CommunityCard">
        <Flex direction="column" alignItems="flex-start">
          <Image
            alt="Road to milford sound"
            src="https://public-cafo.s3.ap-northeast-1.amazonaws.com/vr.jfif"
            width="100%"
          />
          <Flex
            direction="column"
            alignItems="flex-start"
            textAlign="left"
            gap={tokens.space.xs}
          >
            <Flex>
              <Badge size="small" variation="info">
                研究活動
              </Badge>
            </Flex>

            <Heading level={5}>
           授業方法のイノベーション
            </Heading>

            <Text as="span">
            生物の授業で、VR技術を使用して細胞の構造を視覚的に学習してみませんか
            </Text>
            <button
              id="button3"
              className={`btnCafote ${isJoined.button3 ? 'joined' : 'unJoined'}`}
              onClick={() => handleJoinClick('button3')}>
              {isJoined.button3 ? '参加済' : '参加する'}
            </button>
            </Flex>
        </Flex>
      </Card>
     </Flex>
     </div>

      <div className="Footerfixed">
      </div>
      <Footer/>
     < />
  );
}

export default CommunityList;