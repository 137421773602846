import '../App.css';
// === Add the following ===
import { Amplify } from 'aws-amplify';
//import Commentcomponent from '../ui-components/Commentcomponent';
//import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';
import { Storage } from 'aws-amplify';

import { Contents } from "../models";
import { Comment } from "../models";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";

import { useSearchParams } from "react-router-dom";
import Comments from './Comments';

import Header from '../ui-components/StaticHeader_fix'
import Footer from '../ui-components/Footer_fix'
import './CSS/Cafote.css';
import DetailAreaNew2 from '../ui-components/DetailAreaNew2_fix';
import Matome from '../ui-components/Matome_fix';
import Meate from '../ui-components/Meate_fix';
import Nerai from '../ui-components/Nerai_fix';
import Description from '../ui-components/Description_fix';


Amplify.configure(awsExports);
// ======
function DetailAreaNew() {
    
      
  const [searchParams] = useSearchParams();

  console.log("searchParams", searchParams)

  const articleId = searchParams.get('article_id');

  console.log("articleId", articleId)
  
  
//DynamoDBからContents一覧を取得
  const itemsPagination ="";
  const contentItems = useDataStoreBinding({
    type: "collection",
    model: Contents,
    pagination: itemsPagination,
  }).items;

//DynamoDBからComment一覧を取得
  const itemsPaginationcom ="";
  const commentItems = useDataStoreBinding({
    type: "collection",
    model: Comment,
    pagination: itemsPaginationcom,
  }).items;


  
//   console.log(itemsDataStore);
 const contentItem = contentItems.find(i => i.id===articleId);

 const commentItem = commentItems.find(j => j.ContentsComment===articleId);

 console.log("contentItem",contentItem);
 console.log("commentItem",commentItem);

  return (
    <>

    <Header />
      
    <div className="contentmiddle">
      <DetailAreaNew2 contents={ contentItem } />
      <Description contents={ contentItem }/>
      <Nerai contents={ contentItem }/>
      <Meate contents={ contentItem }/>
      <Matome contents={ contentItem }/>
      <Comments articleId={ contentItem?.id } />
    </div>
    
      <Footer />
    
   </>
  );
}

export default DetailAreaNew;



