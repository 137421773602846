
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View,Divider,Button } from "@aws-amplify/ui-react";
export default function SelectStart(props) {
  const { overrides, ...rest } = props;
  const frameThreeZeroOnClick = useNavigateAction({
    type: "url",
    url: "./CheckQuestion",
  });
  return (
    <div>
    <View
      {...getOverrideProps(overrides, "SelectStart")}
      {...rest}
    >
    </View>
      <Flex
        gap="3rem"
        direction="column"
        width="90%"
        height="100%"
        alignItems="center"
        alignContent="center"
        position="absolute"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 31")}
      >
      <div>
      </div>
          <Divider
          border={'5px solid #D17091'}
          ></Divider>
        <Text
          fontFamily="Inter"
          fontSize="1.5rem"
          fontWeight="600"
          color="rgba(205,112,143,1)"
          textAlign="center"
          display="block"
          direction="column"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          {...getOverrideProps(overrides, "text39193708")}
        >あなたに合った教材や<br/>仲間をマッチングします</Text>
        <Button
          className="btnCafote"
          padding="1rem 3rem 1rem 3rem"
          onClick={() => {
            frameThreeZeroOnClick();
          }}
        >診断開始</Button>
          <Divider
          border={'5px solid #D17091'}
          ></Divider>
        </Flex>
      </div>
  );
}
