import '../App.css';
// === Add the following ===
import { Amplify } from 'aws-amplify';
import SelectStart from '../ui-components/SelectStart_fix';
//import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';

import Header from '../ui-components/StaticHeader_fix'
import Footer from '../ui-components/Footer_fix'

Amplify.configure(awsExports);
// ======
function App() {
  return (
    <div className="ContentsList">
    
      <Header />
    
      <div className="contentmiddle">

        <SelectStart/>

      </div>
      <Footer/>

    </div>
  );
}

export default App;