
import React, {useEffect, useState} from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text,Card} from "@aws-amplify/ui-react";
import { Storage } from 'aws-amplify';
export default function DetailAreaNew2_fix(props) {
  const { contents, overrides, ...rest } = props;
  
  const [imagePath,setImagePath]=useState("");
  
  console.log('imagePath:',imagePath)
  
  useEffect(() => {
      async function fetchImage() {
        try {
            const url = await Storage.get(contents.FileSrc);
            setImagePath(url);
            console.log('S3のパス:',url)
        } catch (error) {
            console.error("画像の取得エラー",error);
      }
    }
    fetchImage();
    },[contents]
    )
  
  
  return (
      <Flex
        gap="0rem"
        direction="column"
        shrink="0"
        position="relative"
        alignItems="center"
        backgroundColor="#FFFFFF"
        {...getOverrideProps(overrides, "Frame 36")}
      >
        <Text
          fontFamily="Inter"
          fontSize="30px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="25px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="100%"
          height="auto"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0.5rem"
          whiteSpace="pre-wrap"
          children={contents?.Title}
          {...getOverrideProps(
            overrides,
            "\u6570\u5B66 I\uFF1A\u30B0\u30E9\u30D5\u3068\uFF12\u6B21\u65B9\u7A0B\u5F0F"
          )}
        ></Text>
        <Flex
          gap="0.5em"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          width="95%"
          {...getOverrideProps(overrides, "Tags")}
        >
          <Flex
            gap="0rem"
            direction="row"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="32px"
            padding="6px 8px 6px 8px"
            backgroundColor="rgba(205,112,143,1)"
            {...getOverrideProps(overrides, "Badge4003330")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="600"
              color="#FFFFFF"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={contents?.SubjectName}
              {...getOverrideProps(overrides, "label4003331")}
            ></Text>
          </Flex>
          <Flex
            gap="0rem"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="32px"
            padding="6px 8px 6px 8px"
            backgroundColor="rgba(209,112,145,1)"
            {...getOverrideProps(overrides, "Badge4003332")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="600"
              color="rgba(255,255,255,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={contents?.CategoryName}
              {...getOverrideProps(overrides, "label4003333")}
            ></Text>
          </Flex>
        </Flex>
        <Image
          objectFit="initial"
          objectPosition="50% 50%"
          backgroundColor="initial"
          height="95%"
          width="95%"
          maxWidth="600px"
          maxHeight="600px"
          opacity="100%"
          padding="0.5rem"
          src={imagePath}
          {...getOverrideProps(overrides, "image")}
        ></Image>
      </Flex>
  );
}
