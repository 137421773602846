
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon, Text, Flex,Divider } from "@aws-amplify/ui-react";
export default function QuestionItemHead(props) {
  const { questionBase, overrides, ...rest } = props;
  return (
    <Flex
      direction="column"
      alignItems="center"
      alignContent="center"
      width="100%"
      display="block"
      wrap="wrap"
      gap="1rem"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "QuestionItemHead")}
      {...rest}
    >
      <Text
        fontSize="1.2rem"
        fontWeight="700"
        color="rgba(205,112,143,1)"
        textAlign="center"
        padding="1rem"
        whiteSpace="pre-wrap"
        children="あなたに合った教材や仲間をマッチングします"
        {...getOverrideProps(overrides, "text39455120")}
      ></Text>
      <Flex
      direction="row"
      alignItems="center"
      alignContent="center"
      >
       <Divider
      border={'8px solid #D17091'}
      ></Divider>
      <Text
      children="qNum"
      fontSize="1.5em"
      color="#D17091"
      {...getOverrideProps(overrides, "questionNo")}
      >
      </Text>
      <Divider
      border={'8px solid #D17091'}
      >
      </Divider>
      </Flex>
      <Text
        fontSize="1.5rem"
        fontWeight="500"
        color="rgba(0,0,0,1)"
        textAlign="center"
        justifyContent="unset"
        position="relative"
        whiteSpace="pre-wrap"
        margin="1rem"
        children={questionBase?.QBase}
        {...getOverrideProps(overrides, "text39455121")}
      ></Text>
    </Flex>
  );
}
