import React, { useEffect, useState } from 'react';
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import { CommentItem} from '../ui-components';
import { Input, Button } from '@aws-amplify/ui-react';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';
import * as queries from '../graphql/queries';
import './CSS/Cafote.css';
import Header from '../ui-components/StaticHeader_fix'
import Footer from '../ui-components/Footer_fix'

Amplify.configure(awsExports);

function MyPage() {
  const [userAttributes, setUserAttributes] = useState(null);
  const [UserProfiles, setUserProfileData] = useState(null);
  const [identityId, setIdentityId] = useState(null);
  const [Qanswers, setQanswer] = useState(null);

  useEffect(() => {
    async function fetchUserAttributes() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // ユーザーの属性を取得
        const attributes = user.attributes;
        setUserAttributes({
          name: attributes.name,
          email: attributes.email,
          birthdate: attributes.birthdate,
          userId: attributes.sub, // ユーザーIDはsub属性に格納されています
        });

        // 認証されたユーザーの場合、Cognito Identity ID を取得
        Auth.currentCredentials()
          .then((credentials) => {
            const cognitoIdentityId = credentials.identityId;
            setIdentityId(cognitoIdentityId);
          })
          .catch((error) => {
            console.error('Cognito Identity ID の取得に失敗:', error);
          });
      } catch (error) {
        console.error('ユーザー情報の取得に失敗しました: ', error);
      }
    }

    fetchUserAttributes();
  }, []);

useEffect(() => {
  async function fetchUserProfile() {
    try {
      if (userAttributes && userAttributes.userId) { // userAttributes が null でないことを確認
        const userProfileDataResponse = await API.graphql(
          graphqlOperation(queries.listUserProfiles, {
            filter: { CognitoID: { eq: userAttributes.userId } },
            fields: {
              SchoolType: true,
              PrimarySchoolYear: true,
              JuniorHighSchoolSubject: true,
              HighSchoolSubjectCategory: true,
              IdentityID: true,
              UserIconFileName: true,
            },
          })
        );

        // レスポンスからユーザープロファイルデータを抽出
        const userProfileData =
          userProfileDataResponse.data.listUserProfiles.items[0];

        setUserProfileData(userProfileData); // ユーザープロファイルデータをステートで更新
      } else {
        console.error('userAttributes.userId が存在しないか、userAttributes が null です。');
      }
    } catch (error) {
      console.error('ユーザープロファイルの取得エラー:', error);
    }
  }

  fetchUserProfile();
}, [userAttributes]);


  
useEffect(() => {
  async function fetchQanswer() {
    try {
      if (userAttributes && userAttributes.userId) { // userAttributes が null でないことを確認
        const QanswerDataResponse = await API.graphql(
          graphqlOperation(queries.listQanswers, {
            filter: { UserID: { eq: userAttributes.userId } },
            sort: { field: "createdAt", direction: "desc" },
            fields: {
              createdAt: true,
            }
          })
        );
 
        // レスポンスからユーザープロファイルデータを抽出
        const QanswerData =
          QanswerDataResponse.data.listQanswers.items[0];

        setQanswer(QanswerData); // ユーザープロファイルデータをステートで更新

        console.log('QanswerData:', QanswerData);
      } else {
        console.error('userAttributes.userId が存在しないか、userAttributes が null です。');
      }
    } catch (error) {
      console.error('Qanswerの取得失敗:', error);
    }
  }

  fetchQanswer();
}, [userAttributes]);
function formatDateToJapanese(inputDateStr) {
  // Dateオブジェクトを作成
  const originalDate = new Date(inputDateStr);

  // 日本時間に変換 (UTCからJSTに変換)
  const japanTime = new Date(originalDate.getTime() + 9 * 60 * 60 * 1000); // 9時間分のミリ秒を加算

  // 年、月、日を取得
  const year = japanTime.getFullYear();
  const month = japanTime.getMonth() + 1; // 0から始まるため+1
  const day = japanTime.getDate();

  // "YYYY/MM/DD" フォーマットに変換
  const formattedDate = `${year}/${month}/${day}`;

  return formattedDate;
}

  return (
    <div className="MyPage">
    <Header />
    <div class="contentmiddle">
      {userAttributes ? (
        <div>
          <h2 class="heading-022">診断</h2>
              <a href="./CheckStart">
                <Button className="btnCafote"
                  padding="0.5rem 1.5rem 0.5rem 1.5rem"
                >診断する</Button>
              </a>
              
          {Qanswers ? (
            <div>
              <p>最終診断日: {formatDateToJapanese(Qanswers.createdAt)}</p>
            </div>
          ) : (
            <p>診断結果なし</p>
          )}
            {UserProfiles ? (
            <>  
                <div>
              <p class="heading-022">名前</p>
              <Input  class="ContensText" id="name" defaultValue={userAttributes.name} isDisabled />
              <p class="heading-022">メールアドレス</p>
              <Input class="ContensText" id="Email" defaultValue="KUNI_HOMARE@cafote.com"isDisabled />
                  <p class="heading-022">学校種別</p>
                  <Input  class="ContensText"
                    id="SchoolType"
                    defaultValue={UserProfiles.SchoolType}
                    isDisabled
                  />
                  {UserProfiles.SchoolType === '小学校' ? (
                    <div>
                      <p class="heading-022">学年</p>
                      <Input  class="ContensText"
                        id="Year"
                        defaultValue={`${UserProfiles.PrimarySchoolYear}年生`}
                        isDisabled
                      />
                    </div>
                  ) : UserProfiles.SchoolType === '中学校' ? (
                    <div>
                      <p class="heading-022">教科</p>
                      <Input  class="ContensText"
                        id="Subject"
                        defaultValue={UserProfiles.JuniorHighSchoolSubject}
                        isDisabled
                      />
                    </div>
                  ) : UserProfiles.SchoolType === '高校' ? (
                    <div>
                      <p class="heading-022">教科</p>
                      <Input  class="ContensText"
                        id="Subject"
                        defaultValue={UserProfiles.HighSchoolSubjectCategory}
                        isDisabled
                      />
                    </div>
                  ) : (
                    <p class="heading-022">その他の学校のラベル</p>
                  )}
                </div>
              </>
            ) : (
              <p>ユーザープロファイルが読み込まれていません</p>
            )}
          </div>
        ) : (
          <p>ユーザーがログインしていません</p>
        )}
      </div>
      
  <Footer />
  </div>
  );
}

export default MyPage;
