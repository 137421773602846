
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function Meate(props) {
  const { contents, overrides, ...rest } = props;
  return (
    <Flex
      gap="0rem"
      direction="column"
      backgroundColor="#FFFFFF"
      {...getOverrideProps(overrides, "Meate")}
      {...rest}
    >
        <Text
          textAlign="left" 
          fontSize="1em"
          className="heading-022"
        >説明</Text>
      <Flex
          direction="row"
          width="100%"
          height="auto"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          className="textCard"
        {...getOverrideProps(overrides, "CommentArea")}
      >
        <Text
          textAlign="left"
          children={contents?.Abstract}
          {...getOverrideProps(overrides, "label")}
        ></Text>
      </Flex>
    </Flex>
  );
}
