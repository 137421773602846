import '../App.css';
// === Add the following ===

import ProductCard2Collection from '../ui-components/ProductCard2Collection_bk';
import ProductCard2Collection_bk from '../ui-components/ProductCard2Collection_bk';
import React, { useEffect, useState } from 'react';
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

//import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';
import Header from '../ui-components/StaticHeader_fix'
import Footer from '../ui-components/Footer_fix'


Amplify.configure(awsExports);
// ======
function Home() {
  
  const [userAttributes, setUserAttributes] = useState(null);
  const [UserProfiles, setUserProfileData] = useState(null);
  const [identityId, setIdentityId] = useState(null);
  const [Qanswers, setQanswer] = useState(null);
  
  useEffect(() => {
    async function fetchUserAttributes() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // ユーザーの属性を取得
        const attributes = user.attributes;
        setUserAttributes({
          name: attributes.name,
          email: attributes.email,
          birthdate: attributes.birthdate,
          userId: attributes.sub, // ユーザーIDはsub属性に格納されています
        });
        
        
        // 認証されたユーザーの場合、Cognito Identity ID を取得
        Auth.currentCredentials()
          .then((credentials) => {
            const cognitoIdentityId = credentials.identityId;
            setIdentityId(cognitoIdentityId);
          })
          .catch((error) => {
            console.error('Cognito Identity ID の取得に失敗:', error);
          });
      } catch (error) {
        console.error('ユーザー情報の取得に失敗しました: ', error);
      }
    }

    fetchUserAttributes();
  }, []);
  
  useEffect(() => {
  async function fetchQanswer() {
    try {
      if (userAttributes && userAttributes.userId) { // userAttributes が null でないことを確認
        const QanswerDataResponse = await API.graphql(
          graphqlOperation(queries.listQanswers, {
            filter: { UserID: { eq: userAttributes.userId } },
            fields: {
              createdAt: true,
            },
          })
        );

        // レスポンスからユーザープロファイルデータを抽出
        const QanswerData =
          QanswerDataResponse.data.listQanswers.items[0];

        setQanswer(QanswerData); // ユーザープロファイルデータをステートで更新

        console.log('QanswerData:', QanswerData);
      } else {
        console.error('userAttributes.userId が存在しないか、userAttributes が null です。');
      }
    } catch (error) {
      console.error('Qanswerの取得失敗:', error);
    }
  }

  fetchQanswer();
}, [userAttributes]);
  
  console.log("ユーザーID",userAttributes && userAttributes.userId);
  console.log("ユーザーName",userAttributes && userAttributes.name);
  
  return (
    <div className="Home">
      <Header/>
      <div className="contentmiddle">
        {userAttributes ? (
          <ProductCard2Collection_bk operand={userAttributes.name} />
        ) : (
          <p>Loading user attributes...</p>
        )}
      </div>
      <Footer/>
    </div>
  );
}



export default Home;
