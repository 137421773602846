/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function QuestionItemFoot(props) {
  const { overrides, ...rest } = props;
  const frameThreeOneOnClick = useNavigateAction({
    type: "url",
    url: "/CheckQuestion",
  });
  const frameThreeZeroOnClick = useNavigateAction({
    type: "url",
    url: "/CheckQuestion2",
  });
  return (

    <Flex
      gap="2rem"
      direction="row"
      alignContent="center"
      overflow="hidden"
      position="relative"
      backgroundColor="rgba(255,255,255,1)"
      margin="2rem 0rem 2rem 0rem"
      wrap="wrap"
      justifyContent="center"
      {...getOverrideProps(overrides, "QuestionItemFoot")}
      {...rest}
    >
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        overflow="hidden"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(209,112,145,1)"
        borderRadius="8px"
        padding="1rem"
        backgroundColor="rgba(0,0,0,0)"
        onClick={() => {
          frameThreeOneOnClick();
        }}
        {...getOverrideProps(overrides, "Frame 31")}
      >
        <Text
          fontFamily="Inter"
          fontSize="1rem"
          fontWeight="700"
          color="rgba(174,179,183,1)"
          lineHeight="22px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="＜　　診断開始に戻る"
          {...getOverrideProps(overrides, "text39455125")}
        ></Text>
      </Flex>
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        overflow="hidden"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(209,112,145,1)"
        padding="1rem"
        borderRadius="8px"
        backgroundColor="#D17091"
        onClick={() => {
          frameThreeZeroOnClick();
        }}
        {...getOverrideProps(overrides, "Frame 30")}
      >
        <Text
          fontFamily="Inter"
          fontSize="1rem"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="22px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="次へ　　＞"
          {...getOverrideProps(overrides, "text39455127")}
        ></Text>
      </Flex>
    </Flex>
  );
}
