
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import StaticLogo from "./StaticLogo";
import { Flex, Icon,Divider } from "@aws-amplify/ui-react";
export default function StaticHeader(props) {
  const { overrides, ...rest } = props;
  return (
  <div>
    <Flex
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      padding="6px 5% 6px 5%"
      backgroundColor="#FFFFFF"
      {...getOverrideProps(overrides, "StaticHeader")}
      {...rest}
    >

      <Icon
        width="15px"
        height="15px"
        viewBox={{ minX: 0, minY: 0, width: 27, height: 19 }}
        paths={[
          {
            d: "M0 19L27 19L27 15.8333L0 15.8333L0 19ZM0 11.0833L27 11.0833L27 7.91667L0 7.91667L0 11.0833ZM0 0L0 3.16667L27 3.16667L27 0L0 0Z",
            fill: "#D17091",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "StaicMenuButton")}
      ></Icon>
      <StaticLogo
        shrink="0"
        position="relative"
        color="#D17091"
        {...getOverrideProps(overrides, "StaticLogo")}
      ></StaticLogo>
    </Flex>
    <Divider
    size="small"
    orientation
    ="horizontal" />
    </div>
  );
}
